import { Controller } from "stimulus"

export default class extends Controller {
  static targets = [ "tipo" ]

  initialize() {
    console.log('Hello from stimulus!');
  }

  changeSubjects() {
  	let selected = this.tipoTarget.value;
  	let id_apartado = this.tipoTarget.dataset.apartadoId;

   	$("#tipo-transaccion").attr({readonly: 'readonly'}).css('pointer-events','none');
    $("#meta_id-transaccion").attr({readonly: 'readonly'}).css('pointer-events','none');

    let options = "";

	if (selected == "traspaso") {
		$.ajax({
		  url: `/apartados/${id_apartado}/carteras.json`,
		}).done(function(data) {
		  //console.log(data);
		  data.forEach( function(element, index) {
		    options += "<option value='"+element.id+"'>"+element.nombre+"</option>"
		  });
		  $("#meta_id-transaccion").html(options);
		  $("#meta_type-transaccion").val("Cartera");
		  //desbloquear
		  $("#tipo-transaccion").removeAttr('readonly').css('pointer-events','auto');
		  $("#meta_id-transaccion").removeAttr('readonly').css('pointer-events','auto');
		}).fail(function(error) {
		  console.log(error);
		}).always(function() {
		  console.log("complete");
		});
	}else {
		$.ajax({
		  url: `/apartados/${id_apartado}/personas.json`,
		}).done(function(data) {
		  //console.log(data);
		  data.forEach( function(element, index) {
		    options += "<option value='"+element.id+"'>"+element.nombre+"</option>"
		  });
		  $("#meta_id-transaccion").html(options);
		  $("#meta_type-transaccion").val("Persona");
		  //desbloquear
		  $("#tipo-transaccion").removeAttr('readonly').css('pointer-events','auto');
		  $("#meta_id-transaccion").removeAttr('readonly').css('pointer-events','auto');
		}).fail(function(error) {
		  console.log(error);
		}).always(function() {
		  console.log("complete");
		});
	}
  }

}
